import React, { useState, useEffect } from 'react';
import MainContentHeader from '../MainContentHeader';
import UploadFile from './UploadFile';
import ExpectedInformation from './ExpectedInformation';
import DocumentCard from './DocumentCard';
import ProcessUploadModal from './ProcessUploadModal';
import { uploadFiles } from '../../api';
import './UploadFileContent.css';
import starIcon from '../../assets/star-icon.png';
import returnIcon from '../../assets/return-icon.png';
import downloadTemplateIcon from '../../assets/download-template-icon.png';
import boxIcon from '../../assets/box-icon.png'; 

function UploadFileContent({ setCurrentView, currentView, censusYearData, customerNumber }) {
  const [files, setFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [failedKeywordChecks, setFailedKeywordChecks] = useState({});

  const onFileSelect = (selectedFiles) => {
    const updatedFiles = [...files, ...selectedFiles];
    setFiles(updatedFiles);
  };

  const handleFileDelete = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  const backToTable = () => {
    setCurrentView('results');
  };

  const handleSubmit = async () => {
    if (!files || files.length === 0) {
      alert("Please upload at least one file.");
      return;
    }

    setIsModalOpen(true);

    const formData = new FormData();
    formData.append("customerNumber", customerNumber);
    formData.append("planYear", censusYearData.current_year);

    files.forEach((file) => {
      formData.append("currentYearFiles", file);
    });

    try {
      const response = await uploadFiles(formData);
      console.log('Upload response:', response);

      if (response.keywordResults) {
        const newFailedChecks = {};
        response.keywordResults.forEach(result => {
          newFailedChecks[result.fileName] = !result.hasKeywords;
        });

        console.log('New failed keyword checks:', newFailedChecks);
        setFailedKeywordChecks(newFailedChecks);

        if (Object.values(newFailedChecks).some(value => value)) {
          console.log('Some files failed keyword check');
        } else {
          setCurrentView("results");
        }
      } else {
        setCurrentView("results");
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      alert('An error occurred while uploading files. Please try again.');
    } finally {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    console.log('Current failedKeywordChecks state:', failedKeywordChecks);
  }, [failedKeywordChecks]);

  return (
    <div className="content-wrapper">
      <MainContentHeader
        start_date={censusYearData?.current_year_start_date ?? ''}
        end_date={censusYearData?.current_year_end_date ?? ''}
        title="Census (Plan Year Jul 1, 2022 - Jun 30, 2023)" 
        subtitle="Upload file(s)" 
        description="Upload your information in the supported file formats. Our system will combine them into one view for efficient data management."
        currentView={currentView}
      />
      <div className="upload-section">
        <ExpectedInformation />
        <div className="upload-content">

          <UploadFile onFileSelect={onFileSelect} />

          <div className="top-actions-wrapper">
            <div className="return-to-table" onClick={backToTable}>
              <img src={returnIcon} alt="Return Icon" className="return-icon" />
              <span>RETURN TO TABLE</span>
            </div>
            {files.length > 0 && (
              <button className="continue-button" onClick={handleSubmit}>
                <img src={starIcon} alt="Star Icon" className="button-icon" />
                SUBMIT AND GENERATE
              </button>
            )}
          </div>

          <div className="documents-section">
            <h3 className="documents-title">Documents:</h3>
            {files.length > 0 ? (
              files.map((file, index) => (
                <DocumentCard
                  key={index}
                  fileName={file.name}
                  fileSize={`${(file.size / 1024).toFixed(2)} KB`}
                  fileType={file.name.split('.').pop()}
                  progress={100}
                  onEdit={() => console.log(`Edit file at index ${index}`)}
                  onDelete={() => handleFileDelete(index)}
                  failedKeywordCheck={failedKeywordChecks[file.name]}
                />
              ))
            ) : (
              <div className="upload-empty-state">
                <img src={boxIcon} alt="No documents icon" className="upload-empty-state-icon" />
                <p className="upload-empty-state-text">You haven't uploaded any document yet.</p>
              </div>
            )}
          </div>

          <div className="actions-wrapper">
            {files.length > 0 && (
              <div className="return-to-table" onClick={backToTable}>
                <img src={returnIcon} alt="Return Icon" className="return-icon" />
                <span>RETURN TO TABLE</span>
              </div>
            )}
            {files.length > 0 && (
              <button className="continue-button" onClick={handleSubmit}>
                <img src={starIcon} alt="Star Icon" className="button-icon" />
                SUBMIT AND GENERATE
              </button>
            )}
          </div>
        </div>
      </div>
      <ProcessUploadModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
}

export default UploadFileContent;
