import React, { useState, useEffect } from 'react';
import MainContentHeader from '../MainContentHeader';
import ConflictModal from './ConflictModal';
import BulkConflictModal from './BulkConflictModal';
import HoursWorkedModal from './HoursWorkedModal';
import ResultsTableSection from './ResultsTableSection';
import './ResultsContent.css';
import saveIcon from './../../assets/save-icon.png';
import useResultsData from './useResultsData';
import useProcessingStatus from './useProcessingStatus';
import ProcessingModal from './ProcessingModal';
import Sidebar from './Sidebar';
import sideBarToggleButton from '../../assets/sidebar-toggle-button.png';
import whiteTrashIcon from '../../assets/white-trash-icon.png';
import editPencilButton from '../../assets/edit-pencil-icon.png';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import ValidationErrorModal from './ValidationErrorModal';
import { archiveProject, saveData, submitFinalData } from '../../api';

const filterData = (data, searchTerm, alertFilters) => {
  if (!data || !data.Row) return { Row: {} };

  const lowercasedFilter = searchTerm ? searchTerm.toLowerCase() : '';

  const filteredRows = Object.entries(data.Row).filter(([key, employee]) => {
    const terminationDate = employee.DateOfTermination?.FinalValue;
    const hireDate = employee.DateOfHire?.FinalValue;

    // Check if any field has an 'Error' status
    const hasErrorStatus = Object.values(employee).some(field => 
      field?.FinalValue?.Status === 'Error'
    );

    // Check for GrossCompensation and HoursWorked values greater than 0
    const hasValidGrossCompensation = parseFloat(employee.GrossCompensation?.FinalValue?.Value) > 0;
    const hasValidHoursWorked = parseFloat(employee.HoursWorked?.FinalValue?.Value) > 0;

    if (terminationDate && terminationDate.Source === "PriorYear") {
      if (hireDate?.Value.toLowerCase() === "conflict" || hireDate?.Value.toLowerCase() === "rehire" || hasErrorStatus || hasValidGrossCompensation ||hasValidHoursWorked) {
        // Keep this employee visible
      } else {
        return false;
      }
    }

    // The rest of the filtering logic remains the same
    const matchesSearchTerm = !searchTerm || Object.values(employee).some(field => {
      return field?.FinalValue?.Value?.toString().toLowerCase().includes(lowercasedFilter);
    });

    const matchesAlertFilter = !alertFilters || alertFilters.length === 0 || Object.values(employee).some(field => {
      return alertFilters.includes(field?.FinalValue?.Status);
    });

    return matchesSearchTerm && matchesAlertFilter;
  });

  return { Row: Object.fromEntries(filteredRows) };
};




function ResultsContent({ currentProject, setCurrentView, customerNumber, currentView, setIsLoading, iFinanceId, censusYearData }) {
  const {
    data,
    oldData,
    reportStartDates,
    reportEndDates,
    initialEditableRowData,
    setData,
    setOldData,
    sources,
    fetchResults,
    currentYearErrors,
    currentYearWarnings,
    previousYearErrors,
    previousYearWarnings
  } = useResultsData(currentProject, false, currentView, setIsLoading, iFinanceId, censusYearData, customerNumber);

  const {
    processingStatus,
    showProcessingModal,
    setShowProcessingModal,
    checkProcessingStatusAndShowModal
  } = useProcessingStatus(currentProject, fetchResults);

  
  const [activeTab, setActiveTab] = useState('viewAll');
  const [currentPage, setCurrentPage] = useState(1);
  const [oldCurrentPage, setOldCurrentPage] = useState(1);
  const [showCurrentYearTable, setShowCurrentYearTable] = useState(true);
  const [showPreviousYearTable, setShowPreviousYearTable] = useState(true);
  const [isAddingNewRow, setIsAddingNewRow] = useState(false);
  const [isAddingOldNewRow, setIsAddingOldNewRow] = useState(false);
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [editableOldRowIndex, setEditableOldRowIndex] = useState(null);
  const [editableRowData, setEditableRowData] = useState(initialEditableRowData);
  const [editableOldRowData, setEditableOldRowData] = useState(initialEditableRowData);
  const [currentYearSearchTerm, setCurrentYearSearchTerm] = useState('');
  const [previousYearSearchTerm, setPreviousYearSearchTerm] = useState('');
  const [currentYearAlertFilters, setCurrentYearAlertFilters] = useState([]);
  const [previousYearAlertFilters, setPreviousYearAlertFilters] = useState([]);  
  const [showConflictModal, setShowConflictModal] = useState(false);
  const [showHoursModal, setShowHoursModal] = useState(false);
  const [selectedEmployeeFullName, setSelectedEmployeeFullName] = useState('');
  const [conflictValues, setConflictValues] = useState([]);
  const [isOldDataSaving, setIsOldDataSaving] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idDeletingOldData, setIsDeletingOldData] = useState(null);
  const [employeeKeyToDelete, setEmployeeKeyToDelete] = useState('');
  const [deleteMessage, setDeleteMessage] = useState('');
  const [isDeletingAll, setIsDeletingAll] = useState(false);

  const [validationErrors, setValidationErrors] = useState([]);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [saveRequest, setSaveRequest] = useState(false);

  const [hoursFinalValue, setHoursFinalValue] = useState('');
  const [hoursWorkedNoteFinalValue, setHoursWorkedNoteFinalValue] = useState('');
  const [hoursIsOwner, setHoursIsOwner] = useState(false);

  const [showBulkConflictModal, setShowBulkConflictModal] = useState(false);
  const [bulkConflictSources, setBulkConflictSources] = useState([]);
  const [bulkConflictField, setBulkConflictField] = useState('');
  const [isOldDataBulkResolve, setIsOldDataBulkResolve] = useState(false);

  const [isRehireConflict, setIsRehireConflict] = useState(false);

  useEffect(() => {
    // Update current year filters based on errors and warnings
    setCurrentYearAlertFilters(prevFilters => {
      let updatedFilters = [...prevFilters];
      
      if (currentYearErrors === 0) {
        updatedFilters = updatedFilters.filter(f => f !== 'Error');
      }
      if (currentYearWarnings === 0) {
        updatedFilters = updatedFilters.filter(f => f !== 'Warning');
      }
      
      return updatedFilters;
    });
  
    // Update previous year filters based on errors and warnings
    setPreviousYearAlertFilters(prevFilters => {
      let updatedFilters = [...prevFilters];
      
      if (previousYearErrors === 0) {
        updatedFilters = updatedFilters.filter(f => f !== 'Error');
      }
      if (previousYearWarnings === 0) {
        updatedFilters = updatedFilters.filter(f => f !== 'Warning');
      }
      
      return updatedFilters;
    });
  }, [currentYearErrors, currentYearWarnings, previousYearErrors, previousYearWarnings]);

  useEffect(() => {
    if (saveRequest) {
      SaveData();
      setSaveRequest(false);
    }
  }, [saveRequest, data, oldData]);
  
  const SaveData = async () => {
    setIsLoading(true);
    try {
      const projectId = currentProject;
      
      const reportPeriodStartDate = (reportStartDates && reportStartDates.length > 0)
        ? reportStartDates.map(date => ({
            Value: date.Value,
            Source: date.Source
          }))
        : [];
    
      const reportPeriodEndDate = (reportEndDates && reportEndDates.length > 0)
        ? reportEndDates.map(date => ({
            Value: date.Value,
            Source: date.Source
          }))
        : [];
    
      // Merge the latest data and oldData
      const mergedRowData = { ...data.Row, ...oldData.Row };
      
      const reconstructedData = {
        ReportPeriodStartDate: reportPeriodStartDate,
        ReportPeriodEndDate: reportPeriodEndDate,
        Row: mergedRowData
      };
      
      await saveData(projectId, reconstructedData);
      await fetchResults();
    } catch (error) {
      console.error('Failed to save data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const SubmitFinalData = async () => {
    setIsLoading(true);
    try {
      // Prepare and submit data
      const reportPeriodStartDate = (reportStartDates && reportStartDates.length > 0)
        ? reportStartDates.map(date => ({
            Value: date.Value,
            Source: date.Source
          }))
        : [];
  
      const reportPeriodEndDate = (reportEndDates && reportEndDates.length > 0)
        ? reportEndDates.map(date => ({
            Value: date.Value,
            Source: date.Source
          }))
        : [];
  
      const rowData = {
        ...((data && data.Row) || {}),
        ...((oldData && oldData.Row) || {})
      };
  
      // Check for 'Error' or 'Missing' statuses in FinalValue fields
      let hasErrorsOrMissing = false;
      Object.values(rowData).forEach(employee => {
        Object.values(employee).forEach(field => {
          if (field?.FinalValue?.Status === 'Error') {
            hasErrorsOrMissing = true;
          }
        });
      });
  
      if (hasErrorsOrMissing) {
        alert('Please fix all errors and missing data before submitting.');
        setIsLoading(false);
        return;
      }
  
      const reconstructedData = {
        ReportPeriodStartDate: reportPeriodStartDate,
        ReportPeriodEndDate: reportPeriodEndDate,
        Row: rowData
      };
  
      const response = await submitFinalData(customerNumber, censusYearData.current_year, censusYearData.current_year_end_date, censusYearData.current_year_start_date, iFinanceId, reconstructedData, currentProject);
      if (response.success) {
        let baseUrl;
      
        // Check the current window location to determine the environment
        const currentHostname = window.location.hostname;
      
        if (
          currentHostname === 'censusupload-dev.guidantfinancial.com' ||
          currentHostname === 'localhost' ||
          currentHostname === '127.0.0.1'
        ) {
          baseUrl = 'https://census-dev.guidantfinancial.com/EmployeeStatus';
        } else {
          baseUrl = 'https://census.guidantfinancial.com/EmployeeStatus';
        }
      
        // Redirect to the appropriate URL with iFinanceId
        window.location.href = `${baseUrl}/${iFinanceId}`;
      } else {
        console.warn('Validation errors:', response.errors);
        setValidationErrors(response.errors);
        setShowValidationModal(true);
      }
      
    } catch (error) {
      console.error('Failed to submit final data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  
  
  const handleDeleteRowClick = async (employeeKey, isOldData) => {
    if (processingStatus.isProcessing) {
        if (checkProcessingStatusAndShowModal()) {
            return;
        }
    }

    const employeeData = isOldData ? oldData.Row[employeeKey] : data.Row[employeeKey];

    if (employeeData && employeeData.PriorYearEmployee && employeeData.PriorYearEmployee.FinalValue.Value === "true") {
        alert("You cannot delete a prior year employee.");
        return;
    }

    setIsDeletingOldData(isOldData);
    setEmployeeKeyToDelete(employeeKey);
    setDeleteMessage("Are you sure you want to delete this user?");
    setIsDeletingAll(false);
    setShowDeleteModal(true);
};


  const handleDeleteProjectClick = async () => {
    setIsDeletingOldData(null);
    setEmployeeKeyToDelete(null);
    setDeleteMessage("Are you sure you want to delete everything submitted in the new year census?");
    setIsDeletingAll(true);
    setShowDeleteModal(true);
  }

  const handleConfirmDeleteProject = async () => {
     
    try{
      setIsLoading(true);
      const success = await archiveProject(currentProject);
  
      if (success) {
        setData([]);
        setOldData([]);
        window.location.reload();
      } else {
        alert('Failed to archive the project.');
      }
    
      setShowDeleteModal(false);

    }catch (error){
      console.log(error);
    }finally{
      setIsLoading(false);
    }

    
  }
  

  const handleConfirmDeleteRow = () => {
    if (idDeletingOldData && employeeKeyToDelete) {
      setOldData(prevData => {
        const newData = { ...prevData };
        delete newData.Row[employeeKeyToDelete];
        return newData;
      });
    } else {
      setData(prevData => {
        const newData = { ...prevData };
        delete newData.Row[employeeKeyToDelete];
        return newData;
      });
    }
    setShowDeleteModal(false);
    setSaveRequest(true);
  };
  
  
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const toggleSidebar = () => setIsSidebarVisible(!isSidebarVisible);

  const handlePageChange = (pageNumber, isOldData) => isOldData ? setOldCurrentPage(pageNumber) : setCurrentPage(pageNumber);  
  
  const handleResolveClick = async (values, employeeKey, field, isOldData) => {
    if (processingStatus.isProcessing) {
      if (checkProcessingStatusAndShowModal()) {
        return;
      }
    }

  
    let conflictValues = [];
    let actualField = field;
    const dataSource = isOldData ? oldData.Row : data.Row; // Determine data source based on isOldData
  
    // Check if the field is 'rehire'
    if (field === 'rehire') {
      setIsRehireConflict(true);
      actualField = 'DateOfHire'; // Set the field back to 'DateOfHire'
    } else {
      setIsRehireConflict(false);
    }
  
    // Handling FullName conflicts
    if (actualField === 'FullName') {
      const firstNameValues = dataSource[employeeKey].FirstName.Values;
      const lastNameValues = dataSource[employeeKey].LastName.Values;
  
      // Check if counts are equal and greater than zero
      if (firstNameValues.length === lastNameValues.length && firstNameValues.length > 0) {
        // Counts are equal; no need to show conflict modal
        return;
      }
  
      // Generate all combinations of first names and last names
      if (firstNameValues.length > 0 && lastNameValues.length > 0) {
        for (let i = 0; i < firstNameValues.length; i++) {
          for (let j = 0; j < lastNameValues.length; j++) {
            conflictValues.push({
              firstName: firstNameValues[i],
              lastName: lastNameValues[j],
              employeeKey,
              field: actualField,
              isOldData
            });
          }
        }
      } else if (firstNameValues.length > 0) {
        // Only first names are available
        for (let i = 0; i < firstNameValues.length; i++) {
          conflictValues.push({
            firstName: firstNameValues[i],
            lastName: { Value: '', Source: '' },
            employeeKey,
            field: actualField,
            isOldData
          });
        }
      } else if (lastNameValues.length > 0) {
        // Only last names are available
        for (let j = 0; j < lastNameValues.length; j++) {
          conflictValues.push({
            firstName: { Value: '', Source: '' },
            lastName: lastNameValues[j],
            employeeKey,
            field: actualField,
            isOldData
          });
        }
      } else {
        // Neither first names nor last names are available
        conflictValues.push({
          firstName: { Value: '', Source: '' },
          lastName: { Value: '', Source: '' },
          employeeKey,
          field: actualField,
          isOldData
        });
      }
    } else {
      conflictValues = values.map(value => ({ ...value, employeeKey, field: actualField, isOldData }));
    }
  
    setConflictValues(conflictValues);
    setShowConflictModal(true);
  };
  
  

  const handleBulkResolveClick = (field, isOldData) => {
    const dataToCheck = isOldData ? oldData : data;
    const conflictingRows = [];

  
    // Collect rows with conflicts in the specified field
    Object.entries(dataToCheck.Row).forEach(([key, row]) => {
      if (row[field]?.FinalValue?.Value === "conflict") {
        conflictingRows.push(row);
      }
    });
  
    // Aggregate unique sources and their example values
    const sourceValuesMap = new Map();
  
    conflictingRows.forEach(row => {
      row[field].Values.forEach(valueObj => {
        const source = valueObj.Source;
        const value = valueObj.Value;
  
        // If the source isn't already in the map, add it with the example value
        if (!sourceValuesMap.has(source)) {
          sourceValuesMap.set(source, value);
        }
        // If you prefer to overwrite with the latest value found, remove the if condition
        // sourceValuesMap.set(source, value);
      });
    });
  
    // Convert the map to an array of { Source, Value } objects
    const bulkConflictSourcesArray = Array.from(sourceValuesMap.entries()).map(
      ([source, value]) => ({ Source: source, Value: value })
    );
  
    // Open modal with aggregated sources and example values
    setBulkConflictSources(bulkConflictSourcesArray);
    setBulkConflictField(field);
    setIsOldDataBulkResolve(isOldData);
    setShowBulkConflictModal(true);
  };
  

  const handleBulkConflictChange = (selectedSource) => {
    const dataToUpdate = isOldDataBulkResolve ? { ...oldData } : { ...data };
  
    Object.entries(dataToUpdate.Row).forEach(([key, row]) => {
      if (row[bulkConflictField]?.FinalValue?.Value === 'conflict') {
        const fieldValues = row[bulkConflictField].Values;
  
        // Count occurrences of the selectedSource in the Values array
        const selectedSourceValues = fieldValues.filter(value => value.Source === selectedSource);
  
        // Proceed only if selectedSource is present exactly once
        if (selectedSourceValues.length === 1) {
          const selectedValue = selectedSourceValues[0];
          row[bulkConflictField].FinalValue = { 
            ...selectedValue, 
            Status: 'Valid', 
            Message: 'Conflict resolved.' 
          };
        }
        // Else, skip resolving this conflict
      }
    });
  
    if (isOldDataBulkResolve) {
      setOldData(dataToUpdate);
    } else {
      setData(dataToUpdate);
    }
    setSaveRequest(true);
  };
  
  
  
  
  
  
  
  

  const handleZeroHourClick = async (employeeKey, isOldData, hoursFinalValue, hoursWorkedNoteFinalValue, isOwner) => {
    if (processingStatus.isProcessing) {
      if (checkProcessingStatusAndShowModal()) {
        return;
      }
    }
    setSelectedEmployeeFullName(employeeKey);
    setShowHoursModal(true);
    setIsOldDataSaving(isOldData);
  
    // Store the values in state to pass to the modal
    setHoursFinalValue(hoursFinalValue);
    setHoursWorkedNoteFinalValue(hoursWorkedNoteFinalValue);
    setHoursIsOwner(isOwner);
  };
  

  const handleAddRow = async (isOldData) => {
    if(processingStatus.isProcessing){
      if (checkProcessingStatusAndShowModal()) {
        return;
      }
    }

    if (isOldData) {
      setIsAddingOldNewRow(true);
      setEditableOldRowIndex(null);
      setEditableOldRowData(initialEditableRowData);
      if (!oldData || Object.keys(oldData).length === 0) {
        setOldData([]);
      }
    } else {
      setIsAddingNewRow(true);
      setEditableRowIndex(null);
      setEditableRowData(initialEditableRowData);
      if (!data || Object.keys(data).length === 0) {
        setData([]);
      }
    }
  };

  const handleEditRow = async (key, isOldData) => {
    if (processingStatus.isProcessing) {
      if (checkProcessingStatusAndShowModal()) {
        return;
      }
    }
    const dataToEdit = isOldData ? oldData : data;
    const editableData = isOldData ? setEditableOldRowData : setEditableRowData;
    const setEditableIndex = isOldData ? setEditableOldRowIndex : setEditableRowIndex;

    setEditableIndex(key);
    const employee = dataToEdit.Row[key];
    if (!employee) {
      console.error('Employee data is undefined');
      return;
    }
    editableData({
      originalKey: key,
      FullName: `${employee.FirstName?.FinalValue?.Value || ''} ${employee.MiddleName?.FinalValue?.Value || ''} ${employee.LastName?.FinalValue?.Value || ''}`.trim(),
      FirstName: employee.FirstName?.FinalValue?.Value || '',
      LastName: employee.LastName?.FinalValue?.Value || '',
      MiddleName: employee.MiddleName?.FinalValue?.Value || '',
      SSN: employee.SSN?.FinalValue?.Value || '',
      DateOfBirth: employee.DateOfBirth?.FinalValue?.Value || '',
      DateOfHire: employee.DateOfHire?.FinalValue?.Value || '',
      DateOfReHire: employee.DateOfReHire?.FinalValue?.Value || '',
      DateOfTermination: employee.DateOfTermination?.FinalValue?.Value || '',
      Status: employee.Status?.FinalValue?.Value || '',
      GrossCompensation: employee.GrossCompensation?.FinalValue?.Value || '',
      HoursWorked: employee.HoursWorked?.FinalValue?.Value || '',
      HoursWorkedNote: employee.HoursWorkedNote?.FinalValue?.Value || '',
      DeferALS: employee.DeferALS?.FinalValue?.Value || '',
      ParticipantRoth: employee.ParticipantRoth?.FinalValue?.Value || '',
      CompanyMatch: employee.CompanyMatch?.FinalValue?.Value || '',
      CompanyProfitSharing: employee.CompanyProfitSharing?.FinalValue?.Value || '',
      AnnualLoanPayment: employee.AnnualLoanPayment?.FinalValue?.Value || '',
      RollOver: employee.RollOver?.FinalValue?.Value || '',
      TypeOfStatusChange: employee.TypeOfStatusChange?.FinalValue?.Value || 'Active',
      EndDate: employee.DateOfStatusChange?.FinalValue?.Value || ''
    });
  };

  async function generateOriginalKey(SSN, firstName, lastName, dateOfBirth, customerNumber) {
    // Ensure firstName, lastName, and customerNumber are always trimmed and not null
    firstName = (firstName || '').trim();
    lastName = (lastName || '').trim();
    customerNumber = String(customerNumber || '').trim();
  
    // Handle SSN and dateOfBirth to avoid invalid values
    let standardizedSSN;
    if (SSN && SSN.trim()) {
      standardizedSSN = SSN.replace(/-/g, '');
    } else {
      const dobString = dateOfBirth ? new Date(dateOfBirth).toISOString().split('T')[0] : '';
      standardizedSSN = `${firstName}${lastName}${dobString}`;
    }
  
    const inputString = `${customerNumber}${standardizedSSN}`;
    const encoder = new TextEncoder();
    const data = encoder.encode(inputString);
  
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  
    return hashHex;
  }
  

  function splitFullName(fullName) {
    const nameParts = fullName.trim().split(/\s+/);
    let firstName, middleName, lastName;
  
    if (nameParts.length === 2) {
      [firstName, lastName] = nameParts;
      middleName = '';
    } else if (nameParts.length > 2) {
      firstName = nameParts[0];
      lastName = nameParts[nameParts.length - 1];
      middleName = nameParts.slice(1, -1).join(' ');
    } else {
      firstName = fullName;
      middleName = '';
      lastName = '';
    }
  
    return { firstName, middleName, lastName };
  }


  const handleSaveRow = async (isOldData) => {
    const dataToUpdate = isOldData ? oldData : data;
    const setDataToUpdate = isOldData ? setOldData : setData;
    const editableData = isOldData ? editableOldRowData : editableRowData;

    let {
        FullName,
        FirstName,
        LastName,
        MiddleName,
        DateOfBirth,
        DateOfHire,
        DateOfReHire,
        DateOfTermination,
        Status,
        GrossCompensation,
        GrossCompensationNote,
        HoursWorked,
        HoursWorkedNote,
        DeferALS,
        ParticipantRoth,
        CompanyMatch,
        CompanyProfitSharing,
        AnnualLoanPayment,
        RollOver,
        SSN,
        originalKey: oldKey
    } = editableData;

    if (FullName) {
      const nameParts = splitFullName(FullName);
      FirstName = nameParts.firstName;
      MiddleName = nameParts.middleName || '';
      LastName = nameParts.lastName || '';
    }
  

    if (!FullName?.trim()) {
      alert("Please enter a name");
      return;
    } 
  

    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    FirstName = capitalizeFirstLetter(FirstName);
    MiddleName = capitalizeFirstLetter(MiddleName);
    LastName = capitalizeFirstLetter(LastName);

    const normalizeToDecimal = (value) => {
        if (!value) return '0.00';
        const cleanedValue = value.replace(/[$,]/g, ''); // Remove $ and ,
        const normalizedValue = parseFloat(cleanedValue).toFixed(2); // Convert to float and fix to 2 decimal places
        return normalizedValue;
    };

    GrossCompensation = GrossCompensation ? normalizeToDecimal(GrossCompensation) : GrossCompensation;
    HoursWorked = HoursWorked ? normalizeToDecimal(HoursWorked) : HoursWorked;
    DeferALS = DeferALS ? normalizeToDecimal(DeferALS) : DeferALS;
    ParticipantRoth = ParticipantRoth ? normalizeToDecimal(ParticipantRoth) : ParticipantRoth;
    CompanyMatch = CompanyMatch ? normalizeToDecimal(CompanyMatch) : CompanyMatch;
    CompanyProfitSharing = CompanyProfitSharing ? normalizeToDecimal(CompanyProfitSharing) : CompanyProfitSharing;
    AnnualLoanPayment = AnnualLoanPayment ? normalizeToDecimal(AnnualLoanPayment) : AnnualLoanPayment;
    RollOver = RollOver ? normalizeToDecimal(RollOver) : RollOver;
    

    const newKey = await generateOriginalKey(SSN, FirstName, LastName, DateOfBirth, customerNumber);

    if (!dataToUpdate.Row) {
        dataToUpdate.Row = {};
    }

    const defaultFieldData = () => ({
        Values: [{ Value: '', Source: 'manual_input' }],
        FinalValue: { Value: '', Source: 'manual_input', Status: '', Message: '' }
    });

    const createOrUpdateField = (field, value) => {
      // Retrieve the existing field data
      let existingField = dataToUpdate.Row[oldKey]?.[field];
  
      // Initialize with default data if the field doesn't exist
      if (!existingField || !existingField.Values) {
          existingField = defaultFieldData();
      }
  
      // Find if there's an existing manual_input entry
      let manualInputIndex = existingField.Values.findIndex(v => v.Source === 'manual_input');
  
      if (manualInputIndex === -1) {
          // No existing manual_input entry, add new entry
          existingField.Values.push({ Value: value, Source: 'manual_input' });
      } else {
          // Existing manual_input entry found, replace its Value
          existingField.Values[manualInputIndex].Value = value;
      }
  
      // Update the FinalValue
      existingField.FinalValue = { 
          Value: value, 
          Source: 'manual_input', 
          Status: 'updated', 
          Message: 'Value updated' 
      };
  
      return existingField;
  };
  
  
  

    const fields = {
        FirstName, LastName, MiddleName, DateOfBirth, DateOfHire, DateOfReHire, DateOfTermination,
        Status, GrossCompensation, GrossCompensationNote, HoursWorked, HoursWorkedNote, DeferALS,
        ParticipantRoth, CompanyMatch, CompanyProfitSharing, AnnualLoanPayment, RollOver, SSN
    };

    const newEntry = {};
    for (const [field, value] of Object.entries(fields)) {
        newEntry[field] = createOrUpdateField(field, value || '');
    }

    // Copy all existing fields from the old entry that are not present in the new entry
    if (oldKey && dataToUpdate.Row[oldKey]) {
        Object.keys(dataToUpdate.Row[oldKey]).forEach(key => {
            if (!newEntry.hasOwnProperty(key)) {
                newEntry[key] = dataToUpdate.Row[oldKey][key];
            }
        });
    }

    // Ensure all fields are populated with default values if necessary
    const allFields = [
        'PriorYearEmployee', 'FirstName', 'LastName', 'MiddleName', 'OwnershipPercent', 'SSN',
        'DateOfBirth', 'DateOfHire', 'DateOfReHire', 'DateOfTermination', 'Status', 'GrossCompensation',
        'GrossCompensationNote', 'HoursWorked', 'HoursWorkedNote', 'DeferALS', 'ParticipantRoth', 
        'CompanyMatch', 'CompanyProfitSharing', 'AnnualLoanPayment', 'RollOver'
    ];
    allFields.forEach(field => {
        if (!newEntry[field]) {
            newEntry[field] = defaultFieldData();
        }
    });

    // Remove old entry
    if (oldKey) {
        delete dataToUpdate.Row[oldKey];
    }

    // Determine the correct data set based on DateOfTermination
    const endDateYear = new Date(DateOfTermination).getFullYear();
    const censusYear = parseInt(censusYearData.current_year);
    const targetData = (isNaN(endDateYear) || endDateYear >= censusYear) ? data : oldData;
    const setTargetData = (isNaN(endDateYear) || endDateYear >= censusYear) ? setData : setOldData;
    const removeFromData = (isNaN(endDateYear) || endDateYear >= censusYear) ? setOldData : setData;

    // Add new entry to the target data set
    if (!targetData.Row) {
        targetData.Row = {};
    }
    targetData.Row[newKey] = newEntry;

    // Update state
    setTargetData({ ...targetData });
    removeFromData(prevData => {
        const newData = { ...prevData };
        delete newData.Row[newKey];
        return newData;
    });

    // Reset editing states
    isOldData ? setIsAddingOldNewRow(false) : setIsAddingNewRow(false);
    isOldData ? setEditableOldRowIndex(null) : setEditableRowIndex(null);

    setSaveRequest(true);
};

  const handleSaveHoursWorked = (employeeKey, hoursWorked, description) => {
    const updatedData = isOldDataSaving ? { ...oldData } : { ...data };
    const employee = updatedData.Row[employeeKey];
    
    if (!employee.HoursWorked) {
      employee.HoursWorked = {
        Values: [],
        FinalValue: {}
      };
    }
  
    if (!employee.HoursWorkedNote) {
      employee.HoursWorkedNote = {
        Values: [],
        FinalValue: {}
      };
    }
  
    const newHoursWorkedValue = { Value: hoursWorked, Source: 'manual_input' };
    const newHoursWorkedNoteValue = { Value: description, Source: 'manual_input' };
  
    employee.HoursWorked.Values.push(newHoursWorkedValue);
    employee.HoursWorked.FinalValue = newHoursWorkedValue;
  
    employee.HoursWorkedNote.Values.push(newHoursWorkedNoteValue);
    employee.HoursWorkedNote.FinalValue = newHoursWorkedNoteValue;
  
    isOldDataSaving ? setOldData(updatedData) : setData(updatedData);
    setSaveRequest(true);
  };
  


  const handleInputChange = (field, value, isOldData) => {
    const setEditableData = isOldData ? setEditableOldRowData : setEditableRowData;
  
    if (['DateOfHire', 'DateOfBirth', 'DateOfTermination', 'DateOfReHire'].includes(field)) {
      if (value) {
        const [year, month, day] = value.split('-');
        const formattedDate = `${month}-${day}-${year}`;
        setEditableData(prevData => ({ ...prevData, [field]: formattedDate }));
      } else {
        setEditableData(prevData => ({ ...prevData, [field]: '' }));
      }
    } else {
      setEditableData(prevData => ({ ...prevData, [field]: value }));
    }
  };
  
  
  const handleConflictChange = (selectedConflict) => {
    const { employeeKey, field, Value, Source } = selectedConflict;
    
    // Create a copy of both data and oldData
    const newData = JSON.parse(JSON.stringify(data));
    const newOldData = JSON.parse(JSON.stringify(oldData));
    
    // Function to update the employee record
    const updateEmployeeRecord = (dataObj, key) => {
      if (dataObj.Row[key]) {
        if (field === 'FullName') {
          const { firstName, lastName } = selectedConflict;
          if (dataObj.Row[key].FirstName) {
            dataObj.Row[key].FirstName.FinalValue = { 
              Value: firstName.Value, 
              Source: firstName.Source, 
              Status: "Valid", 
              Message: "First Name conflict resolved." 
            };
          }
          if (dataObj.Row[key].LastName) {
            dataObj.Row[key].LastName.FinalValue = { 
              Value: lastName.Value, 
              Source: lastName.Source, 
              Status: "Valid", 
              Message: "Last Name conflict resolved." 
            };
          }
        } else if (dataObj.Row[key][field]) {
          dataObj.Row[key][field].FinalValue = { 
            Value, 
            Source, 
            Status: "Valid", 
            Message: `${field} conflict resolved.` 
          };
        }
      }
    };
  
    // Get the key without "_duplicate" suffix
    const mainKey = employeeKey.replace('_duplicate', '');
  
    // Update both the duplicate and main records in both data and oldData
    [newData, newOldData].forEach(dataObj => {
      updateEmployeeRecord(dataObj, employeeKey);
      updateEmployeeRecord(dataObj, mainKey);
    });
  
    // Update the state
    setData(newData);
    setOldData(newOldData);
  
    // Trigger save operation
    setSaveRequest(true);
  };
  
  const handleAlertClick = (filter, isOldData) => {
    handlePageChange(1, isOldData);
    if (isOldData) {
      setPreviousYearAlertFilters(prevFilters =>
        prevFilters.includes(filter)
          ? prevFilters.filter(f => f !== filter)
          : [...prevFilters, filter]
      );
    } else {
      setCurrentYearAlertFilters(prevFilters =>
        prevFilters.includes(filter)
          ? prevFilters.filter(f => f !== filter)
          : [...prevFilters, filter]
      );
    }
  };
  

  const filteredData = filterData(data, currentYearSearchTerm, currentYearAlertFilters);
  const oldFilteredData = filterData(oldData, previousYearSearchTerm, previousYearAlertFilters);
  

  function formatDate(dateString) {
    if (!dateString) return '';
  
    const [year, month, day] = dateString.split('-');
    const date = new Date(Date.UTC(year, month - 1, day));
  
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      timeZone: 'UTC', // Specify UTC time zone
    }).toUpperCase();
  }
  
  const priorCensusTitle = `PAST YEAR CENSUS CHANGES (${formatDate(censusYearData?.prior_year_start_date)} - ${formatDate(censusYearData?.prior_year_end_date)})`;

  

  return (
    <div className={`results-content-container ${isSidebarVisible ? 'with-sidebar' : 'no-sidebar'}`}>
      {isSidebarVisible && <Sidebar sources={sources} setCurrentView={setCurrentView} toggleSidebar={toggleSidebar} />}
      <div className="toggle-button-wrapper">
        <img
          src={sideBarToggleButton}
          alt="Toggle Sidebar"
          className={`toggle-sidebar-button ${!isSidebarVisible ? 'rotated' : ''}`}
          onClick={toggleSidebar}
        />
      </div>
      <div className="results-content-wrapper">
        <div className="content-wrapper">
          <MainContentHeader
            start_date={censusYearData?.current_year_start_date ?? ''}
            end_date={censusYearData?.current_year_end_date ?? ''}
            subtitle="Submit Employee and Owner Information"
            description="The table displays employees and owners that were active at the beginning of the plan year. To complete your census, add new employees, verify and update statuses, and provide payroll details. Resolve any errors and review warnings to proceed to the next step."
            currentView={currentView}
          />

        <button className="add-employee-button" onClick={() => handleAddRow(false)}>
          <img src={editPencilButton} alt="Add Employee" className="icon" />
          ADD EMPLOYEE
        </button>
          {processingStatus.isProcessing && (
            <div className="processing-warning" onClick={() => checkProcessingStatusAndShowModal()}>
              <span className="processing-warning-icon">
                <i className="fas fa-info-circle"></i>
              </span>
              Editing the table is unavailable while documents are processing. Click to learn more.
            </div>
          )}
          <ResultsTableSection
            title="CURRENT YEAR CENSUS"
            data={filteredData}
            currentPage={currentPage}
            rowsPerPage={8}
            isAddingNewRow={isAddingNewRow}
            editableRowIndex={editableRowIndex}
            editableRowData={editableRowData}
            handlePageChange={(pageNumber) => handlePageChange(pageNumber, false)}
            handleEditRow={(key) => handleEditRow(key, false)}
            handleSaveRow={() => handleSaveRow(false)}
            handleInputChange={(field, value) => handleInputChange(field, value, false)}
            handleResolveClick={(values, employeeKey, field) => handleResolveClick(values, employeeKey, field, false)}
            handleBulkResolveClick={(field) => handleBulkResolveClick(field, false)}
            handleZeroHourClick={(employeeKey, isOldData, hoursFinalValue, hoursWorkedNoteFinalValue, isOwner) => handleZeroHourClick(employeeKey, false, hoursFinalValue, hoursWorkedNoteFinalValue, isOwner)}
            initialEditableRowData={initialEditableRowData}
            setIsAddingNewRow={setIsAddingNewRow}
            setEditableRowIndex={setEditableRowIndex}
            setEditableRowData={setEditableRowData}
            setCurrentView={setCurrentView}
            isOldData={false}
            showTable={showCurrentYearTable}
            setShowTable={setShowCurrentYearTable}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            searchTerm={currentYearSearchTerm}
            setSearchTerm={(term) => setCurrentYearSearchTerm(term)}
            handleAddRow={() => handleAddRow(false)}
            alertFilters={currentYearAlertFilters}
            handleAlertClick={(filter) => handleAlertClick(filter, false)}
            setData = {setData}
            setOldData = {setOldData}
            handleDeleteRowClick={handleDeleteRowClick}
            errors={currentYearErrors}
            warnings={currentYearWarnings}
          />
          {(Object.keys(oldFilteredData.Row || {}).length > 0 || (previousYearAlertFilters && previousYearAlertFilters.length > 0) || previousYearSearchTerm) && (
            <>
              <div className="divider"></div>
              <ResultsTableSection
                title={priorCensusTitle}
                data={oldFilteredData}
                currentPage={oldCurrentPage}
                rowsPerPage={8}
                isAddingNewRow={isAddingOldNewRow}
                editableRowIndex={editableOldRowIndex}
                editableRowData={editableOldRowData}
                handlePageChange={(pageNumber) => handlePageChange(pageNumber, true)}
                handleEditRow={(key) => handleEditRow(key, true)}
                handleSaveRow={() => handleSaveRow(true)}
                handleInputChange={(field, value) => handleInputChange(field, value, true)}
                handleResolveClick={(values, employeeKey, field) => handleResolveClick(values, employeeKey, field, true)}
                handleBulkResolveClick={(field) => handleBulkResolveClick(field, true)}
                handleZeroHourClick={(employeeKey, isOldData, hoursFinalValue, hoursWorkedNoteFinalValue, isOwner) => handleZeroHourClick(employeeKey, true, hoursFinalValue, hoursWorkedNoteFinalValue, isOwner)}
                initialEditableRowData={initialEditableRowData}
                setIsAddingNewRow={setIsAddingOldNewRow}
                setEditableRowIndex={setEditableOldRowIndex}
                setEditableRowData={setEditableOldRowData}
                setCurrentView={setCurrentView}
                isOldData={true}
                showTable={showPreviousYearTable}
                setShowTable={setShowPreviousYearTable}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                searchTerm={previousYearSearchTerm}
                setSearchTerm={(term) => setPreviousYearSearchTerm(term)}
                handleAddRow={() => handleAddRow(true)}
                alertFilters={previousYearAlertFilters}
                handleAlertClick={(filter) => handleAlertClick(filter, true)}
                setData = {setData}
                setOldData = {setOldData}
                handleDeleteRowClick={handleDeleteRowClick}
                errors={previousYearErrors}
                warnings={previousYearWarnings}
              />
            </>
          )}
          <div className="save-button-container">
            <button className="delete-button" onClick={handleDeleteProjectClick}>
              <img src={whiteTrashIcon} alt="Delete" className="icon" />
              Start Over
            </button>
            <button className="save-button" onClick={SubmitFinalData}>
              <img src={saveIcon} alt="Save" className="icon" />
              Save and Continue
            </button>
          </div>
          <ConflictModal
            show={showConflictModal}
            onClose={() => setShowConflictModal(false)}
            conflictValues={conflictValues}
            reportEndDates={reportEndDates}
            onApplyChange={handleConflictChange}
            isRehireConflict={isRehireConflict}
          />
          <BulkConflictModal
            show={showBulkConflictModal}
            onClose={() => setShowBulkConflictModal(false)}
            sources={bulkConflictSources}
            onApplyChange={handleBulkConflictChange}
            bulkConflictField={bulkConflictField}
          />

          <HoursWorkedModal
            show={showHoursModal}
            onClose={() => setShowHoursModal(false)}
            onSave={handleSaveHoursWorked}
            employeeFullName={selectedEmployeeFullName}
            hoursFinalValue={hoursFinalValue}
            hoursWorkedNoteFinalValue={hoursWorkedNoteFinalValue}
            hoursIsOwner={hoursIsOwner}
          />

          <ProcessingModal
            show={showProcessingModal}
            onClose={() => setShowProcessingModal(false)}
            processingStatus={processingStatus}
          />
          <DeleteConfirmationModal
            show={showDeleteModal}
            onClose={handleCloseDeleteModal}
            handleConfirmDeleteRow={handleConfirmDeleteRow}
            handleConfirmDeleteProject={handleConfirmDeleteProject}
            deleteMessage={deleteMessage}
            isDeletingAll={isDeletingAll}
          />
          <ValidationErrorModal
            show={showValidationModal}
            onClose={() => setShowValidationModal(false)}
            errors={validationErrors}
          />
        </div>
      </div>
    </div>
  );
  
}

export default ResultsContent;
