const hostname = window.location.hostname;

export const identityServerUrl = (hostname === 'localhost' || 
                                  hostname === '127.0.0.1' || 
                                  hostname === 'censusupload-dev.guidantfinancial.com' || 
                                  hostname === 'guidant-census-data.azurewebsites.net' || 
                                  hostname === 'censusupload-stg.guidantfinancial.com' || 
                                  hostname === 'guidant-census-data-stg.azurewebsites.net')
  ? "https://identity-dev.guidantfinancial.com"
  : "https://identity.guidantfinancial.com";

export const endSessionEndpoint = `${identityServerUrl}/connect/endsession`; // End session endpoint

export const isTokenValid = (token) => {
  try {
    // Check if token is a string (raw token)
    if (typeof token === 'string') {
      console.error('isTokenValid called with raw token:', token);
      console.trace();
      return false;
    }
    
    const currentTime = Math.floor(Date.now() / 1000);
    let isValid = true;

    if (token.exp <= currentTime) {
      console.error('Token validation failed: Token has expired');
      isValid = false;
    }
    if (token.iss !== identityServerUrl) {
      console.error('Token validation failed: Invalid issuer');
      isValid = false;
    }
    if (token.aud !== "censusupload") {
      console.error('Token validation failed: Invalid audience');
      isValid = false;
    }

    return isValid;
  } catch (error) {
    console.error('Error validating token:', error);
    return false;
  }
};

export const generateNonce = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

const getRedirectUri = () => {
  const hostname = window.location.hostname;

  if (hostname === 'localhost' || hostname === '127.0.0.1') {
    return encodeURIComponent('https://localhost:3000/signin-oidc');
  } else if (hostname === 'censusupload-dev.guidantfinancial.com' || hostname === 'guidant-census-data.azurewebsites.net') {
    return encodeURIComponent('https://censusupload-dev.guidantfinancial.com/signin-oidc');
  } else if (hostname === 'censusupload-stg.guidantfinancial.com' || hostname === 'guidant-census-data-stg.azurewebsites.net') {
    return encodeURIComponent('https://censusupload-stg.guidantfinancial.com/signin-oidc');
  } else if (hostname === 'censusupload-prod.guidantfinancial.com' || hostname === 'guidant-census-data-prod.azurewebsites.net') {
    return encodeURIComponent('https://censusupload-prod.guidantfinancial.com/signin-oidc');
  }

  throw new Error('Unknown environment: Unable to determine redirect URI');
};


const getLogoutRedirectUri = () => {
  const hostname = window.location.hostname;

  if (hostname === 'localhost' || hostname === '127.0.0.1') {
    return encodeURIComponent('https://localhost:3000/');
  } else if (hostname === 'censusupload-dev.guidantfinancial.com' || hostname === 'guidant-census-data.azurewebsites.net') {
    return encodeURIComponent('https://censusupload-dev.guidantfinancial.com/');
  } else if (hostname === 'censusupload-stg.guidantfinancial.com' || hostname === 'guidant-census-data-stg.azurewebsites.net') {
    return encodeURIComponent('https://censusupload-stg.guidantfinancial.com/');
  } else if (hostname === 'censusupload-prod.guidantfinancial.com' || hostname === 'guidant-census-data-prod.azurewebsites.net') {
    return encodeURIComponent('https://censusupload-prod.guidantfinancial.com/');
  }

  throw new Error('Unknown environment: Unable to determine logout redirect URI');
};



export const redirectToIdentityServer = () => {
  const redirectUri = getRedirectUri();
  let nonce = sessionStorage.getItem('auth_nonce');
  if (!nonce) {
    nonce = generateNonce();
    sessionStorage.setItem('auth_nonce', nonce);
  }
  const loginUrl = `${identityServerUrl}/connect/authorize?client_id=censusupload&redirect_uri=${redirectUri}&response_type=code%20id_token&scope=openid%20profile%20email%20role%20register&response_mode=fragment&nonce=${nonce}`;
  window.location.href = loginUrl;
};

export const logout = (idToken) => {
  console.log(idToken);
  localStorage.removeItem('id_token');
  localStorage.removeItem('decoded_token');
  
  const redirectUri = getLogoutRedirectUri();
  const logoutUrl = `${endSessionEndpoint}?id_token_hint=${encodeURIComponent(idToken)}&post_logout_redirect_uri=${redirectUri}`;
  
  console.log("Logout URL:", logoutUrl);  // Log the URL for debugging
  window.location.href = logoutUrl;
};
