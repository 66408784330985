import React, { useMemo, useState, useEffect } from 'react';
import EmptyState from './EmptyState';
import editIcon from './../../assets/edit-icon.png';
import trashIcon from './../../assets/trash-icon.png';
import saveIcon from './../../assets/blue-save-icon.png';
import resolveIcon from './../../assets/resolve-icon.png';
import greenDot from './../../assets/green-dot.png';
import greyDot from './../../assets/grey-dot.png';
import importDataIcon from './../../assets/import-data-icon.png';
import plusIcon from './../../assets/plus-icon.png';
import paginationLeftIcon from './../../assets/pagination-arrow-left.png';
import paginationRightIcon from './../../assets/pagination-arrow-right.png';
import warningIconCircle from './../../assets/warning-icon-circle.png';
import ownerIcon from  './../../assets/owner-icon.png';
import blueDot from './../../assets/blue-dot.png';
import notedBadge from './../../assets/noted-badge.png';
import DataSourceDisplay from './DataSourceDisplay';
import HoursWorkedTooltip from './HoursWorkedTooltip';


import './ResultsTable.css';

const ResultsTable = ({
  data,
  currentPage,
  rowsPerPage,
  isAddingNewRow,
  editableRowIndex,
  editableRowData,
  handlePageChange,
  handleEditRow,
  handleSaveRow,
  handleInputChange,
  handleResolveClick,
  handleBulkResolveClick,
  handleZeroHourClick,
  setIsAddingNewRow,
  setEditableRowIndex,
  initialEditableRowData,
  handleAddRow,
  setEditableRowData,
  setCurrentView,
  isOldData,
  alertFilters,
  handleDeleteRowClick,
  conflictColumns
}) => {
  const [dataSourceDisplay, setDataSourceDisplay] = useState({ source: '', anchorEl: null });
  const [hoursWorkedTooltip, setHoursWorkedTooltip] = useState({ show: false, position: { x: 0, y: 0 } });
  const [tooltip, setTooltip] = useState({ show: false, source: '', position: null });

  const rows = Object.entries(data.Row || {});
  const getFieldValue = (field, isNameField = false) => {
    return field?.FinalValue?.Value || (isNameField ? '' : '-');
  };  

  const sortedRows = useMemo(() => {
    return rows.sort(([, employeeA], [, employeeB]) => {
      const fullNameA = `${getFieldValue(employeeA.FirstName, true)} ${getFieldValue(employeeA.MiddleName, true)} ${getFieldValue(employeeA.LastName, true)}`.trim().toLowerCase();
      const fullNameB = `${getFieldValue(employeeB.FirstName, true)} ${getFieldValue(employeeB.MiddleName, true)} ${getFieldValue(employeeB.LastName, true)}`.trim().toLowerCase();
      return fullNameA.localeCompare(fullNameB);
    });
  }, [rows]);

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .table-container {
        -webkit-overflow-scrolling: touch;
        overflow-y: hidden;
        overflow-x: scroll;
      }
    `;
    document.head.appendChild(style);

    // Ensure the scrollbars are always visible
    const tableContainer = document.querySelector('.table-container');
    tableContainer.style.overflowX = 'scroll';
    tableContainer.style.overflowY = 'hidden';

    // Cleanup the added style when component unmounts
    return () => {
      document.head.removeChild(style);
    };
  }, []);
  

  const handleCellMouseEnter = (event, source) => {
    const rect = event.target.getBoundingClientRect();
    setTooltip({
      show: true,
      source,
      position: { x: rect.left, y: rect.bottom }
    });
  };

  const handleCellMouseLeave = () => {
    setTooltip({ show: false, source: '', position: null });
  };
  
  const formatCurrency = (value) => {
    const numberValue = parseFloat(value);
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(numberValue);
  };


  const totalPages = Math.ceil(sortedRows.length / rowsPerPage);
  const startRow = (currentPage - 1) * rowsPerPage;
  const endRow = startRow + rowsPerPage;
  const currentRows = sortedRows.slice(startRow, endRow);

  const showEmptyState = !isAddingNewRow && (rows.length === 0);

  const getCellColorAndMessage = (status, alertFilters, message) => {
    const colorClasses = {
      'Error': 'error-cell',
      'Warning': 'warning-cell'
    };
  
    const textColorClasses = {
      'Error': 'error-message',
      'Warning': 'warning-message'
    };
  
    let colorClass = '';
    let textColorClass = '';
    let cellMessage = message || '';
  
    // Always color the text based on status
    if (status === 'Error') {
      textColorClass = textColorClasses['Error'];
    } else if (status === 'Warning') {
      textColorClass = textColorClasses['Warning'];
    }
  
    // Only apply background color when alert filters are active
    if (alertFilters.includes('Error') && status === 'Error') {
      colorClass = colorClasses['Error'];
    } else if (alertFilters.includes('Warning') && status === 'Warning') {
      colorClass = colorClasses['Warning'];
    }
  
    return { colorClass, textColorClass, message: cellMessage };
  };
  

  const renderTableHeader = () => {
    const headers = [
      { name: 'NAME *', field: 'FullName' },
      { name: 'HIRE DATE *', field: 'DateOfHire' },
      { name: 'BIRTH DATE *', field: 'DateOfBirth' },
      { name: 'SSN *', field: 'SSN' },
      { name: 'STATUS *', field: 'Status' },
      { name: 'TERMINATION DATE', field: 'DateOfTermination' },
      { name: 'HOURS WORKED *', field: 'HoursWorked' },
      { name: 'GROSS COMPENSATION *', field: 'GrossCompensation' },
      { name: 'PRE-TAX CONTRIBUTIONS', field: 'DeferALS' },
      { name: 'ROTH CONTRIBUTIONS', field: 'ParticipantRoth' },
      { name: 'EMPLOYER MATCH', field: 'CompanyMatch' },
      { name: 'PROFIT SHARING', field: 'CompanyProfitSharing' },
      { name: 'ROLLOVER', field: 'RollOver' },
      { name: 'LOAN PAYMENT', field: 'AnnualLoanPayment' },
    ];

    return (
      <thead>
        <tr>
          <th><input type="checkbox" /></th>
          {headers.map(header => (
            <th key={header.field}>
              {header.name}
              {conflictColumns.includes(header.field) && (
                <button className="resolve-button-header" onClick={() => handleBulkResolveClick(header.field)}>
                  RESOLVE ALL <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
                </button>
              )}
            </th>
          ))}
          <th></th>
        </tr>
      </thead>
    );
    
  };
  
  
  
  
  
  const renderInputRow = () => (
    <tr className="editable-row" key="editable-row">
      <td><input type="checkbox" /></td>
      <td>
        <input
          type="text"
          className="editable-input"
          placeholder="ex: John Doe"
          value={editableRowData.FullName || ''}
          onChange={(e) => handleInputChange('FullName', e.target.value)}
        />
        {editableRowData.FinalValue?.Message && <div className="cell-message">{editableRowData.FinalValue.Message}</div>}
      </td>
      <td>
        <input
          type="date"
          className="editable-input"
          value={editableRowData.DateOfHire ? formatDateForInput(editableRowData.DateOfHire) : ''}
          onChange={(e) => handleInputChange('DateOfHire', e.target.value)}
        />
        {editableRowData.DateOfHire?.FinalValue?.Message && <div className="cell-message">{editableRowData.DateOfHire.FinalValue.Message}</div>}
      </td>
      <td>
        <input
          type="date"
          className="editable-input"
          value={editableRowData.DateOfBirth ? formatDateForInput(editableRowData.DateOfBirth) : ''}
          onChange={(e) => handleInputChange('DateOfBirth', e.target.value)}
        />
        {editableRowData.DateOfBirth?.FinalValue?.Message && <div className="cell-message">{editableRowData.DateOfBirth.FinalValue.Message}</div>}
      </td>
      <td>
        <input
          type="text"
          className="editable-input"
          placeholder="XXX-XX-XXXX"
          value={editableRowData.SSN || ''}
          onChange={(e) => handleInputChange('SSN', e.target.value)}
        />
        {editableRowData.SSN?.FinalValue?.Message && <div className="cell-message">{editableRowData.SSN.FinalValue.Message}</div>}
      </td>
      <td>
        <select
          className="editable-input editable-select"
          value={editableRowData.Status || ''}
          onChange={(e) => handleInputChange('Status', e.target.value)}
        >
          <option value="Active">Active</option>
          <option value="Termination">Terminated</option>
        </select>
        {editableRowData.Status?.FinalValue?.Message && <div className="cell-message">{editableRowData.Status.FinalValue.Message}</div>}
      </td>
      <td>
        <input
          type="date"
          className="editable-input"
          value={editableRowData.DateOfTermination ? formatDateForInput(editableRowData.DateOfTermination) : ''}
          onChange={(e) => handleInputChange('DateOfTermination', e.target.value)}
        />
        {editableRowData.DateOfTermination?.FinalValue?.Message && <div className="cell-message">{editableRowData.DateOfTermination.FinalValue.Message}</div>}
      </td>
      <td>
        <input
          type="text"
          className="editable-input"
          placeholder="ex: 10,400"
          value={editableRowData.HoursWorked || ''}
          onChange={(e) => handleInputChange('HoursWorked', e.target.value)}
          onFocus={(e) => {
            const value = e.target.value;
            if (value === '0' || value === '0.0' || value === '0.00') {
              setEditableRowData(prevData => ({
                ...prevData,
                HoursWorked: ''
              }));
            }
          }}
        />
        {editableRowData.HoursWorked?.FinalValue?.Message && <div className="cell-message">{editableRowData.HoursWorked.FinalValue.Message}</div>}
      </td>
      <td>
        <input
          type="text"
          className="editable-input"
          placeholder="ex: 3,000"
          value={editableRowData.GrossCompensation || ''}
          onChange={(e) => handleInputChange('GrossCompensation', e.target.value)}
          onFocus={(e) => {
            const value = e.target.value;
            if (value === '0' || value === '0.0' || value === '0.00') {
              setEditableRowData(prevData => ({
                ...prevData,
                GrossCompensation: ''
              }));
            }
          }}
        />
        {editableRowData.GrossCompensation?.FinalValue?.Message && <div className="cell-message">{editableRowData.GrossCompensation.FinalValue.Message}</div>}
      </td>
      <td>
        <input
          type="text"
          className="editable-input"
          placeholder="ex: 1,000"
          value={editableRowData.DeferALS || ''}
          onChange={(e) => handleInputChange('DeferALS', e.target.value)}
          onFocus={(e) => {
            const value = e.target.value;
            if (value === '0' || value === '0.0' || value === '0.00') {
              setEditableRowData(prevData => ({
                ...prevData,
                DeferALS: ''
              }));
            }
          }}
        />
        {editableRowData.DeferALS?.FinalValue?.Message && <div className="cell-message">{editableRowData.DeferALS.FinalValue.Message}</div>}
      </td>
      <td>
        <input
          type="text"
          className="editable-input"
          placeholder="ex: 500"
          value={editableRowData.ParticipantRoth || ''}
          onChange={(e) => handleInputChange('ParticipantRoth', e.target.value)}
          onFocus={(e) => {
            const value = e.target.value;
            if (value === '0' || value === '0.0' || value === '0.00') {
              setEditableRowData(prevData => ({
                ...prevData,
                ParticipantRoth: ''
              }));
            }
          }}
        />
        {editableRowData.ParticipantRoth?.FinalValue?.Message && <div className="cell-message">{editableRowData.ParticipantRoth.FinalValue.Message}</div>}
      </td>
      <td>
        <input
          type="text"
          className="editable-input"
          placeholder="ex: 200"
          value={editableRowData.CompanyMatch || ''}
          onChange={(e) => handleInputChange('CompanyMatch', e.target.value)}
          onFocus={(e) => {
            const value = e.target.value;
            if (value === '0' || value === '0.0' || value === '0.00') {
              setEditableRowData(prevData => ({
                ...prevData,
                CompanyMatch: ''
              }));
            }
          }}
        />
        {editableRowData.CompanyMatch?.FinalValue?.Message && <div className="cell-message">{editableRowData.CompanyMatch.FinalValue.Message}</div>}
      </td>
      <td>
        <input
          type="text"
          className="editable-input"
          placeholder="ex: 300"
          value={editableRowData.CompanyProfitSharing || ''}
          onChange={(e) => handleInputChange('CompanyProfitSharing', e.target.value)}
          onFocus={(e) => {
            const value = e.target.value;
            if (value === '0' || value === '0.0' || value === '0.00') {
              setEditableRowData(prevData => ({
                ...prevData,
                CompanyProfitSharing: ''
              }));
            }
          }}
        />
        {editableRowData.CompanyProfitSharing?.FinalValue?.Message && <div className="cell-message">{editableRowData.CompanyProfitSharing.FinalValue.Message}</div>}
      </td>
      <td>
        <input
          type="text"
          className="editable-input"
          placeholder="ex: 100"
          value={editableRowData.RollOver || ''}
          onChange={(e) => handleInputChange('RollOver', e.target.value)}
          onFocus={(e) => {
            const value = e.target.value;
            if (value === '0' || value === '0.0' || value === '0.00') {
              setEditableRowData(prevData => ({
                ...prevData,
                RollOver: ''
              }));
            }
          }}
        />
        {editableRowData.RollOver?.FinalValue?.Message && <div className="cell-message">{editableRowData.RollOver.FinalValue.Message}</div>}
      </td>
      <td>
        <input
          type="text"
          className="editable-input"
          placeholder="ex: 150"
          value={editableRowData.AnnualLoanPayment || ''}
          onChange={(e) => handleInputChange('AnnualLoanPayment', e.target.value)}
          onFocus={(e) => {
            const value = e.target.value;
            if (value === '0' || value === '0.0' || value === '0.00') {
              setEditableRowData(prevData => ({
                ...prevData,
                AnnualLoanPayment: ''
              }));
            }
          }}
        />
        {editableRowData.AnnualLoanPayment?.FinalValue?.Message && <div className="cell-message">{editableRowData.AnnualLoanPayment.FinalValue.Message}</div>}
      </td>
      <td className="editable-buttons">
        <div className="buttons-container">
          <button className="save-button-row-edit" onClick={handleSaveRow}>
            <img src={saveIcon} alt="Save" className="small-save-icon" />
            SAVE
          </button>
          <button className="cancel-button" onClick={() => { setIsAddingNewRow(false); setEditableRowIndex(null); setEditableRowData(initialEditableRowData); }}>
            X
          </button>
        </div>
      </td>

    </tr>
  );
  
  const formatDateForInput = (dateString) => {
    const [month, day, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };

  const renderFullName = (
    firstName,
    lastName,
    ownershipPercent,
    handleResolveClick,
    employeeKey,
    isOldData,
    alertFilters,
    handleCellMouseEnter,
    handleCellMouseLeave
  ) => {
    const fullName = `${getFieldValue(firstName, true)} ${getFieldValue(lastName, true)}`;
    const isOwner = parseFloat(ownershipPercent?.FinalValue?.Value || "0") > 0;
    const fullNameConflict = firstName?.FinalValue?.Value === 'conflict' || lastName?.FinalValue?.Value === 'conflict';
  
    // Prioritize 'Error' over 'Warning' for name statuses
    const status =
      firstName?.FinalValue?.Status === 'Error' || lastName?.FinalValue?.Status === 'Error'
        ? 'Error'
        : firstName?.FinalValue?.Status === 'Warning' || lastName?.FinalValue?.Status === 'Warning'
        ? 'Warning'
        : '';
  
    // Determine the message based on the status
    let message = '';
    if (firstName?.FinalValue?.Status === 'Error') {
      message = firstName?.FinalValue?.Message || '';
    } else if (lastName?.FinalValue?.Status === 'Error') {
      message = lastName?.FinalValue?.Message || '';
    } else if (firstName?.FinalValue?.Status === 'Warning') {
      message = firstName?.FinalValue?.Message || '';
    } else if (lastName?.FinalValue?.Status === 'Warning') {
      message = lastName?.FinalValue?.Message || '';
    }
  
    const { colorClass, textColorClass, message: cellMessage } = getCellColorAndMessage(
      status,
      alertFilters,
      message
    );
  
    const source = firstName?.FinalValue?.Source || lastName?.FinalValue?.Source || 'N/A';
  
    const handleNameMouseEnter = (e) => {
      handleCellMouseEnter(e, source);
    };
  
    return (
      <div className="full-name-wrapper">
        <div className={`full-name-container ${colorClass}`}>
          {fullNameConflict ? (
            <button 
              className="resolve-button" 
              onClick={() => handleResolveClick([...firstName.Values, ...lastName.Values], employeeKey, 'FullName', isOldData)}
            >
              RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
            </button>
          ) : (
            <>
              <span 
                className={`truncate-name ${isOwner ? 'with-owner-icon' : ''}`}
                onMouseEnter={handleNameMouseEnter}
                onMouseLeave={handleCellMouseLeave}
                style={{ display: 'inline-block' }}
              >
                {fullName}
              </span>
              {isOwner && (
                <span className="owner-pill">
                  <img src={blueDot} alt="Owner Blue Dot" className="owner-dot" />
                  <span className="owner-text">Owner</span>
                </span>
              )}
            </>
          )}
        </div>
        {(status === 'Error' || status === 'Warning') && cellMessage && (
          <div className={`cell-message ${textColorClass}`}>{cellMessage}</div>
        )}
      </div>
    );
  };
  
  
  
  

  const renderCell = (field, fieldName, handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters) => {
    const value = field?.FinalValue?.Value;
    const status = field?.FinalValue?.Status;
    const message = field?.FinalValue?.Message;
    
    // Updated to include textColorClass for the message
    const { colorClass, textColorClass, message: cellMessage } = getCellColorAndMessage(status, alertFilters, message);
  
    const valueProps = {
      onMouseEnter: (e) => handleCellMouseEnter(e, field?.FinalValue?.Source || 'N/A'),
      onMouseLeave: handleCellMouseLeave,
    };
  
    if (fieldName === 'Status') {
      const displayStatus = (value === 'Death' || value === 'Retirement' || value === 'Terminated' || value === 'Termination' || value === 'Inactive') ? 'Terminated' : 'Active';
      
      return (
        <td className={`status-cell ${colorClass}`}>
          {value === 'conflict' ? (
            <button className="resolve-button" onClick={() => handleResolveClick(field.Values, employeeKey, 'Status', isOldData)}>
              RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
            </button>
          ) : (
            <div className={`status-pill ${displayStatus === 'Terminated' ? 'terminated' : 'active'}`}>
              <img src={displayStatus === 'Terminated' ? greyDot : greenDot} alt="Status Dot" className="status-dot" />
              <span className="status-text" {...valueProps}>{displayStatus}</span>
            </div>
          )}
          {(status === 'Error' || status === 'Warning') && cellMessage && (
          <div className={`cell-message ${textColorClass}`}>{cellMessage}</div>
        )}
        </td>
      );
    }
  
    if (fieldName === 'DateOfHire') {
      const value = field?.FinalValue?.Value;
      if (value && value.toLowerCase() === 'rehire') {
        return (
          <td className={colorClass}>
            <button className="resolve-button" onClick={() => handleResolveClick(field.Values, employeeKey, 'rehire', isOldData)}>
              RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
            </button>
            {(status === 'Error' || status === 'Warning') && cellMessage && (
          <div className={`cell-message ${textColorClass}`}>{cellMessage}</div>
        )}
          </td>
        );
      }
    }
  
    if (value === 'conflict') {
      return (
        <td className={colorClass}>
          <button className="resolve-button" onClick={() => handleResolveClick(field.Values, employeeKey, fieldName, isOldData)}>
            RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
          </button>
          {(status === 'Error' || status === 'Warning') && cellMessage && (
          <div className={`cell-message ${textColorClass}`}>{cellMessage}</div>
        )}
        </td>
      );
    }
  
    if (fieldName === 'SSN') {
      const cleanedSSN = value ? value.replace(/-/g, '') : '';
      const maskedSSN = cleanedSSN.length === 9 ? `XXX-XX-${cleanedSSN.slice(-4)}` : cleanedSSN;
      return (
        <td className={colorClass}>
          <span {...valueProps}>{maskedSSN}</span>
          {(status === 'Error' || status === 'Warning') && cellMessage && (
          <div className={`cell-message ${textColorClass}`}>{cellMessage}</div>
        )}
        </td>
      );
    }
  
    if (fieldName === 'HoursWorked') {
      let value = field?.FinalValue?.Value || '-';
      if (isNaN(parseFloat(value))) {
        value = '-';
      }
      const hoursFinalValue = field?.FinalValue?.Value;
      const hoursWorkedNoteFinalValue = employee?.HoursWorkedNote?.FinalValue?.Value;
      const ownershipPercent = parseFloat(employee?.OwnershipPercent?.FinalValue?.Value || '0');
      const isOwner = ownershipPercent > 0;
    
      return (
        <td 
          className={`${colorClass} zero-hour-hover`}
          onClick={() => handleZeroHourClick(employeeKey, isOldData, hoursFinalValue, hoursWorkedNoteFinalValue, isOwner)}
        >
          <div className="hours-worked-container">
            <span {...valueProps}>{value}</span>
    
            {(() => {
              if (status === 'Warning') {
                const showTooltip = parseFloat(value) >= 1 && parseFloat(value) <= 499 && isOwner;
    
                if (showTooltip) {
                  return (
                    <img 
                      src={warningIconCircle} 
                      alt="Warning Icon" 
                      className="hours-info-icon" 
                      onMouseEnter={(e) => {
                        const rect = e.target.getBoundingClientRect();
                        setHoursWorkedTooltip({
                          show: true,
                          position: { x: rect.right + 10, y: rect.top }
                        });
                      }}
                      onMouseLeave={() => setHoursWorkedTooltip({ show: false, position: { x: 0, y: 0 } })}
                    />
                  );
                } else {
                  return (
                    <img src={warningIconCircle} alt="Warning Icon" className="hours-info-icon" />
                  );
                }
              }
            })()}
    
          </div>
          {hoursWorkedNoteFinalValue && hoursWorkedNoteFinalValue.trim() !== '' && (
            <img src={notedBadge} alt="Noted Badge" className="noted-badge-icon noted-badge-hover" />
          )}
          {(status === 'Error' || status === 'Warning') && cellMessage && (
            <div className={`cell-message ${textColorClass}`}>{cellMessage}</div>
          )}
        </td>
      );
    }
    
  
    const fieldsToNormalize = [
      'GrossCompensation',
      'HoursWorked',
      'DeferALS',
      'ParticipantRoth',
      'CompanyMatch',
      'CompanyProfitSharing',
      'AnnualLoanPayment',
      'RollOver'
    ];
  
    const isValueNumber = value && !isNaN(parseFloat(value)) && isFinite(value);
  
    return (
      <td className={colorClass}>
        <span {...valueProps}>
          {value ? (fieldsToNormalize.includes(fieldName) && isValueNumber ? formatCurrency(value) : value) : '-'}
        </span>
        {(status === 'Error' || status === 'Warning') && cellMessage && (
          <div className={`cell-message ${textColorClass}`}>{cellMessage}</div>
        )}
      </td>
    );
  };
  
  
  const renderEmployeeRow = ([employeeKey, employee], index) => {
    if (editableRowIndex === employeeKey) {
      return renderInputRow(); 
    }
  
    const FullName = `${getFieldValue(employee.FirstName, true)} ${getFieldValue(employee.LastName, true)}`;
    const fullNameStatus = employee.FirstName?.FinalValue?.Status || employee.LastName?.FinalValue?.Status;
    
    // Get the colorClass for the entire cell
    const { colorClass, textColorClass, message: cellMessage } = getCellColorAndMessage(fullNameStatus, alertFilters, employee.FirstName?.FinalValue?.Message || employee.LastName?.FinalValue?.Message);
  
    return (
      <tr key={employeeKey}>
        <td><input type="checkbox" /></td>
        <td className={`name-column ${colorClass}`}> {/* Apply colorClass here */}
          {renderFullName(employee.FirstName, employee.LastName, employee.OwnershipPercent, handleResolveClick, employeeKey, isOldData, alertFilters, handleCellMouseEnter, handleCellMouseLeave)}
        </td>
        {renderCell(employee.DateOfHire, 'DateOfHire', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.DateOfBirth, 'DateOfBirth', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.SSN, 'SSN', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.Status, 'Status', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.DateOfTermination, 'DateOfTermination', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.HoursWorked, 'HoursWorked', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.GrossCompensation, 'GrossCompensation', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.DeferALS, 'DeferALS', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.ParticipantRoth, 'ParticipantRoth', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.CompanyMatch, 'CompanyMatch', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.CompanyProfitSharing, 'CompanyProfitSharing', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.RollOver, 'RollOver', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.AnnualLoanPayment, 'AnnualLoanPayment', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        <td className="icons">
          <img src={trashIcon} alt="Delete" className="icon delete-icon" onClick={() => handleDeleteRowClick(employeeKey, isOldData)} />
          <img src={editIcon} alt="Edit" className="icon edit-icon" onClick={() => handleEditRow(employeeKey)} />
        </td>
      </tr>
    );
  };
  
  
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
  
    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);
  
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push('...');
      }
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }
      pageNumbers.push(totalPages);
    }
  
    return pageNumbers.map((page, index) => (
      page === '...' ? (
        <span key={index} className="pagination-ellipsis">...</span>
      ) : (
        <button
          key={index}
          className={`pagination-button ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </button>
      )
    ));
  };

  
  

  return (
    <div className="results-section">
      {showEmptyState ? (
        <div className="empty-state-wrapper">
          <EmptyState handleAddRow={handleAddRow} setCurrentView={setCurrentView} />
        </div>
      ) : null}
      <div className="table-container">
        <table className="results-table">
          {renderTableHeader()}
          <tbody>
            {!showEmptyState && (
              <>
                {isAddingNewRow && renderInputRow()}
                {currentRows.map(renderEmployeeRow)}
              </>
            )}
          </tbody>
        </table>
      </div>
      <table className="fake-row-table">
        <tbody>
          <tr className="fake-row">
            <td colSpan="16">
              <button className="import-data-button" onClick={() => setCurrentView("upload")}>
                <img src={importDataIcon} alt="Import Data" className="icon" />
                Import data
              </button>
              <button className="new-employee-button" onClick={handleAddRow}>
                <img src={plusIcon} alt="New Employee" className="icon" />
                New employee
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="footer-section">
        <div className="total-members">
          Team Members: {rows.length}
        </div>
        <div className="pagination-controls">
          <button
            className="pagination-arrow"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <img src={paginationLeftIcon} alt="Previous" />
          </button>
          {renderPageNumbers()}
          <button
            className="pagination-arrow"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <img src={paginationRightIcon} alt="Next" />
          </button>
        </div>
      </div>
{tooltip.show && (
        <DataSourceDisplay 
          source={tooltip.source} 
          position={tooltip.position}
        />
      )}
    {hoursWorkedTooltip.show && (
      <HoursWorkedTooltip 
        position={hoursWorkedTooltip.position}
      />
    )}
    </div>
  );
  
};

export default ResultsTable;
