import React, { useState } from 'react';
import './ConflictModal.css';
import closeIcon from './../../assets/close-icon.png';
import infoIcon from './../../assets/orange-info-icon.png';

const ConflictModal = ({
  show,
  onClose,
  conflictValues,
  reportEndDates,
  onApplyChange,
  isRehireConflict
}) => {
  const [selectedConflict, setSelectedConflict] = useState(null);
  const [manualDate, setManualDate] = useState('');

  const fieldDisplayNames = {
    GrossCompensation: 'Gross Compensation',
    FullName: 'Full Name',
    FirstName: 'First Name',
    LastName: 'Last Name',
    MiddleName: 'Middle Name',
    OwnershipPercent: 'Ownership Percent',
    SSN: 'SSN',
    DateOfBirth: 'Date of Birth',
    DateOfHire: 'Date of Hire',
    DateOfReHire: 'Date of Rehire',
    DateOfTermination: 'Date of Termination',
    Status: 'Status',
    GrossCompensationNote: 'Gross Compensation Note',
    HoursWorked: 'Hours Worked',
    HoursWorkedNote: 'Hours Worked Note',
    DeferALS: 'Defer ALS',
    ParticipantRoth: 'Participant Roth',
    CompanyMatch: 'Company Match',
    CompanyProfitSharing: 'Company Profit Sharing',
    AnnualLoanPayment: 'Annual Loan Payment',
    RollOver: 'Rollover',
  };

  if (!show) {
    return null;
  }

  // Function to get the display name for a field
  const getFieldDisplayName = (field) => {
    return fieldDisplayNames[field] || field; // Fall back to field if not mapped
  };

  const getReportEndDate = (source) => {
    const dateObj = reportEndDates.find((item) => item.Source === source);
    return dateObj ? dateObj.Value : '';
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split('-');
    return `${month}-${day}-${year}`;
  };

  const handleApplyChange = () => {
    console.log("Selected Conflict: ", selectedConflict);
    if (selectedConflict) {
      if (selectedConflict.Source === 'manual_input') {
        const { employeeKey, field, isOldData } = conflictValues[0];
        const formattedDate = formatDate(manualDate);
        onApplyChange({
          Value: formattedDate,
          Source: 'manual_input',
          employeeKey,
          field,
          isOldData
        });
      } else {
        onApplyChange(selectedConflict);
      }
      onClose();
    }
  };

  const currentValues = conflictValues.filter(
    (conflict) => conflict.Source !== 'PriorYear'
  );

  console.log(currentValues);
  const priorYearValues = conflictValues.filter(
    (conflict) => conflict.Source === 'PriorYear'
  );

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>
            {isRehireConflict
              ? 'Is this a rehired employee?'
              : `Choose the Correct ${getFieldDisplayName(conflictValues[0].field)}`}
          </h2>
          <img
            src={closeIcon}
            alt="Close"
            className="modal-close-icon"
            onClick={onClose}
          />
        </div>

        {/* Horizontal line below the header */}
        <hr className="modal-header-divider" />

        {/* Discrepancy message */}
        <p className="discrepancy-text">
          We found discrepancies in the values of the documents you uploaded.
        </p>

        {/* Conditional rendering of the banner */}
        {isRehireConflict && (
          <div className="rehire-banner">
            <img src={infoIcon} alt="Info" className="info-icon" />
            <span>Please enter the NEW hire date.</span>
          </div>
        )}

        <table className="modal-table">
          <thead>
            <tr>
              <th>{getFieldDisplayName(conflictValues[0].field).toUpperCase()}</th>
              <th>SOURCE</th>
            </tr>
          </thead>
          <tbody>
            {currentValues.map((conflict, index) => {
              if (conflict.field === 'FullName') {
                // Special handling for FullName field
                const fullName = `${conflict.firstName?.Value || ''} ${conflict.lastName?.Value || ''}`.trim();

                // Check if the sources are the same; if they are, use only one
                const firstNameSource = conflict.firstName?.Source || '';
                const lastNameSource = conflict.lastName?.Source || '';
                const source = firstNameSource === lastNameSource ? firstNameSource : `${firstNameSource} / ${lastNameSource}`;

                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="radio"
                        name="conflictSelection"
                        checked={selectedConflict === conflict}
                        onChange={() => setSelectedConflict(conflict)}
                      />
                      {fullName || 'No Name Provided'}
                    </td>
                    <td>{source || 'No Source'}</td>
                  </tr>
                );
              } else {
                // Handling for other fields remains the same
                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="radio"
                        name="conflictSelection"
                        checked={selectedConflict === conflict}
                        onChange={() => setSelectedConflict(conflict)}
                      />
                      {conflict.Value}
                    </td>
                    <td>{conflict.Source}</td>
                  </tr>
                );
              }
            })}
            {isRehireConflict && (
              <tr>
                <td>
                  <input
                    type="radio"
                    name="conflictSelection"
                    checked={
                      selectedConflict && selectedConflict.Source === 'manual_input'
                    }
                    onChange={() => setSelectedConflict({ Source: 'manual_input' })}
                  />
                  <input
                    type="date"
                    value={manualDate || ''}
                    onChange={(e) => setManualDate(e.target.value)}
                  />
                </td>
                <td>Manual Input</td>
              </tr>
            )}
          </tbody>
        </table>

        {priorYearValues.length > 0 && (
          <>
            <div className="modal-header previous-census">
              <h2>Previous Census Data</h2>
            </div>
            <table className="modal-table">
              <thead>
                <tr>
                  <th>{getFieldDisplayName(conflictValues[0].field).toUpperCase()}</th>
                  <th>SOURCE</th>
                </tr>
              </thead>
              <tbody>
                {priorYearValues.map((conflict, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="radio"
                        name="conflictSelection"
                        checked={selectedConflict === conflict}
                        onChange={() => setSelectedConflict(conflict)}
                      />
                      {conflict.Value}
                    </td>
                    <td>{conflict.Source}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}

        <div className="modal-button-container">
          <button className="modal-apply-button" onClick={handleApplyChange}>
            SAVE
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConflictModal;
